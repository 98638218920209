<template>
    <div class="main-wrapper">
        <div class="title">
            <div class="titleData">{{price.goods_name}}商品</div>
        </div>
        <div class="select-wrapper">
          <div class="select-left">
            <span class="text">商品价格：</span>
              <el-input v-model="price.goods_discount_price" disabled="disabled" style="width: 60%;" placeholder="请输入商品价格"></el-input>
                <span style="margin-left: 10px;">元</span>
          </div>
          <div class="select-left">
              <el-button type="primary" @click="add">添加优惠卷</el-button>
          </div>
      </div>
       <el-scrollbar>
            <el-table :data="conponListTable" class="customTable" style="width: 100%; flex: 1">
                 <el-table-column  width="100px"  align="center"></el-table-column>
                 <el-table-column prop="name" label="优惠券类型" width="350px">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.coupon_type" placeholder="请选择" @change="typefun(scope.row)" clearable>
                            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </template>
                 </el-table-column>
                 <!-- <el-table-column  label="优惠券名称"  width="250px">
                        <template slot-scope="scope">
                             <el-input style="width: 80%;" maxlength=18   v-model="scope.row.coupon_name" placeholder="2-18个字"></el-input>                          
                        </template>
                  </el-table-column> -->
                   <el-table-column  label="优惠券内容" width="400px">
                        <template slot-scope="scope">
                            <span style="padding-right: 10px;">满</span>
                             <el-input style="width: 35%;" oninput="value=value.replace(/[^\d]/g,'')" v-model="scope.row.coupon_full" ></el-input>     
                               <span v-if="scope.row.coupon_type==2" style="padding-right: 10px;">&ensp;&ensp;打&ensp;</span>
                                <span v-else style="padding-right: 10px;">&ensp;&ensp;减&ensp;</span>  
                             <el-input style="width: 35%;"  @blur="blur(scope.row)" v-model="scope.row.coupon_minus" ></el-input> 
                             <span v-if="scope.row.coupon_type==2" style="padding-right: 10px;">&ensp;折</span>                                            
                             <span v-else style="padding-right: 10px;">&ensp;元</span>                                            
                        </template>
                  </el-table-column>
         
                   <el-table-column  label="发放量" width="220px">
                        <template slot-scope="scope">
                             <el-input style="width: 70%" oninput="value=value.replace(/[^\d]/g,'')" v-model="scope.row.grant_count" ></el-input>  
                               <span style="padding-left: 10px;">张</span>                        
                        </template>
                  </el-table-column>
                   <el-table-column  label="限领" width="220px">
                        <template slot-scope="scope">
                             <el-input style="width: 70%;" oninput="value=value.replace(/[^\d]/g,'')" v-model="scope.row.everyone_count" ></el-input>  
                               <span style="padding-left: 10px;">张</span>                        
                        </template>
                  </el-table-column>
                   <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-link type="danger" :underline="false" @click="delShopList(scope.$index, scope.row)">删除</el-link>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                          <img src="/static/img/no_data.92ce4507.png" style="margin-bottom: 10px;width: 400px;">
                          <span>暂无数据</span>
                        </div>
                    </template>
            </el-table>
        </el-scrollbar>
         <div class="footer-button">
            <el-button type="primary" @click="backTo">上一步</el-button>
            <el-button type="primary" @click="next">下一步</el-button>
          </div>
    </div>
</template>



<script>
import { validateGoodsCoupon } from "@/utils/apis";
export default {
    data(){
        return{
            input:'',
            conponListTable:[
               
            ],
            typefunType:1,
            tiem_id:'',
            discount:0,
            typeList:[
                {
                    id:1,
                    name:'指定商品满减卷'
                },
                 {
                    id:2,
                    name:'指定商品折扣卷'
                }
            ],
            price:'',
            sum_id:''
            
        }
    },
    created(){
        this.price = JSON.parse(localStorage.getItem("message"))
        this.$bus.$on("title", () => {        
            this.price = JSON.parse(localStorage.getItem("message"))
        })
        this.editEstablish()
    },
    methods:{
        //判断折扣和满减
        typefun(row){
             let rule = /^(?=1\.[0-9]|[1-9]\.\d).{3}$|^([1-9])$/
            if(row.coupon_type ==1){
                this.typefunType = 1
                console.log(this.typefunType,'jian')
            }else{
                 this.typefunType = 2
                  if(!rule.test(row.coupon_minus)){
                        this.$message.success("折扣区间1~9.9");
                        return
                    }
            } 
        },
        blur(row){
            let rule = /^(?=1\.[0-9]|[1-9]\.\d).{3}$|^([1-9])$/
            if( this.typefunType == 2){
                 if(!rule.test(row.coupon_minus)){
                        this.$message.success("折扣区间1~9.9");
                        return
                    }
            }
        },
        editEstablish(){
            if (this.$route.query.id) { 
                let eiitList = JSON.parse(localStorage.getItem("commodityeditData"))
                this.conponListTable = eiitList.goods_coupon
            }
        },
        // 返回上一步
        backTo() {
            this.$router.push({
                path: "/liveStreaming/newComManagementScript",query: { id: this.$route.query.id }  
            });
        },
         //下一步
        next(){
           
           let params = {  
              goods_coupon:this.conponListTable,
            };
             validateGoodsCoupon(params).then((res) => {
              if (res.code == 200) {
                 localStorage.setItem("conponDate", JSON.stringify(this.conponListTable));
                this.$router.push({
                    path: "/liveStreaming/newComManagementAnswers",query: { id: this.$route.query.id } 
                });
                this.$bus.$emit("conpons");
              }
            }).catch((err) => {
              console.error("err", err);
            });

         
        },

        //添加
        add(){
            this.conponListTable.push({
                // id:this.tiem_id++,
                coupon_name:'',
                coupon_type:'',
                coupon_full:'',
                coupon_minus:'',
                grant_count:'',
                everyone_count:'',
                // discount:0
            })
        },
        //删除
        delShopList(index) {
             this.conponListTable.splice(index, 1);
            this.$message({          
                type: "success",
                message: "删除成功",
            });
        },
    }
}
</script>


<style lang="scss" scoped>
    .main-wrapper {
        height: 75%;
        padding: 0 30px 0 10px;     
         .title{
            text-align: center;
            padding-bottom: 30px;
            .titleData{
                font-weight: bold; 
                font-size: 14px;
            }
        }  
        .el-scrollbar {
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                height: 100%;
                overflow-x: hidden;    
                 .el-scrollbar__view {
                    height: 100%;
                }
            }
        }
        .select-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .select-left {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                    .text {
                    font-size: 14px;
                    color: #333333;
                    margin-right: 10px;
                }
            }           
        }
        .footer-button{
            text-align: center;
            padding-top: 15px;
        } 
    }
</style>